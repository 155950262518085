import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { ForceSymbol, LifeCycle, TpaSrcQueryParamProviderSymbol } from '@wix/thunderbolt-symbols'
import { TpaMessageContextPicker } from './tpaMessageContextPicker'
import { TpaMessageContextPickerDs } from './tpaMessageContextPicker.ds'
import {
	MasterPageTpaPropsCacheSymbol,
	TpaSectionSymbol,
	TpaContextMappingSymbol,
	TpaSrcBuilderSymbol,
} from './symbols'
import { TpaPropsCacheFactory } from './tpaPropsCache'
import { TpaSrcBuilder } from './tpaSrcBuilder'
import { TpaContextMappingFactory } from './tpaContextMapping'
import { TpaSection } from './tpaSection'
import {
	AppSectionTpaSrcQueryParamProvider,
	BaseTpaSrcQueryParamProvider,
	BITpaSrcQueryParamProvider,
	CommonConfigTpaSrcQueryParamProvider,
	ConsentPolicyTpaSrcQueryParamProvider,
	CurrencyTpaSrcQueryParamProvider,
	InstanceTpaSrcQueryParamProvider,
	RouteTpaSrcQueryParamProvider,
} from './tpaSrcQueryParamProviders'

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppDidMountHandler).to(TpaMessageContextPicker)
	bind(MasterPageTpaPropsCacheSymbol).to(TpaPropsCacheFactory)
	bind(TpaContextMappingSymbol).to(TpaContextMappingFactory)
	bind(TpaSrcBuilderSymbol).to(TpaSrcBuilder)
	bind(TpaSectionSymbol).to(TpaSection)
	bind(TpaSrcQueryParamProviderSymbol).to(BaseTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(InstanceTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(CurrencyTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(BITpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(ConsentPolicyTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(CommonConfigTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(RouteTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(AppSectionTpaSrcQueryParamProvider)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind(ForceSymbol).to(TpaMessageContextPickerDs)
	bind(TpaSrcBuilderSymbol).to(TpaSrcBuilder)
	bind(TpaSectionSymbol).to(TpaSection)
	bind(TpaSrcQueryParamProviderSymbol).to(BaseTpaSrcQueryParamProvider)
	// bind(TpaSrcQueryParamProviderSymbol).to(InstanceTpaSrcQueryParamProvider)
	bind(TpaSrcQueryParamProviderSymbol).to(CurrencyTpaSrcQueryParamProvider)
	// bind(TpaSrcQueryParamProviderSymbol).to(BITpaSrcQueryParamProvider)
	// bind(TpaSrcQueryParamProviderSymbol).to(ConsentPolicyTpaSrcQueryParamProvider)
	// bind(TpaSrcQueryParamProviderSymbol).to(CommonConfigTpaSrcQueryParamProvider)
	// bind(TpaSrcQueryParamProviderSymbol).to(RouteTpaSrcQueryParamProvider)
	// bind(TpaSrcQueryParamProviderSymbol).to(AppSectionTpaSrcQueryParamProvider)
}

export { TpaHandlersManagerSymbol, TpaSectionSymbol, name } from './symbols'
export { MasterPageTpaPropsCacheSymbol, TpaSrcBuilderSymbol, TpaContextMappingSymbol }
export * from './types'
